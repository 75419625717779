import { render, staticRenderFns } from "./LandingDemoHero.vue?vue&type=template&id=6e7328d0&"
import script from "./LandingDemoHero.vue?vue&type=script&lang=js&"
export * from "./LandingDemoHero.vue?vue&type=script&lang=js&"
import style0 from "./LandingDemoHero.vue?vue&type=style&index=0&id=6e7328d0&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingDemoButton: require('/app/components/landing/demo/LandingDemoButton.vue').default})
